import { Injectable } from '@angular/core';

import { AuthenticationService } from '@shared/services/authentication.service';
import { Wallet } from '../models/bracelit/wallet';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {

  constructor(private authenticationService: AuthenticationService) {}

  async fetchWallets(wristbandId: string, wristbands) {
    const client_id = this.authenticationService.clientId;
    let wallets = [];
    if (client_id && client_id !== '') {
      for (var i=0 ; i < wristbands.length ; i++)
      {
        if (wristbands[i].id == wristbandId) {
          wallets = wristbands[i].wallets;
        }
      }
      return wallets;
    }

    return null;
  }

  async fetchWallet(walletId: string, wristbandId: string, wristbands) {
    const client_id = this.authenticationService.clientId;
    let wallet: Wallet;
    if (client_id && client_id !== '') {
      localStorage.setItem('selectedWalletId', walletId);

      for (var i=0 ; i < wristbands.length ; i++)
      {
          if (wristbands[i].id == wristbandId) {
            for (var ii=0 ; ii < wristbands[i].wallets.length ; ii++) {
              if (wristbands[i].wallets[ii].id == walletId) {
                wallet = wristbands[i].wallets[ii];
              }
            }
          }
      }

      return wallet;
    }

    return null;
  }
}
