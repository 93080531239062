import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

/**
 * ApiService, performs the requests, refreshing the JWT token. (Authorization)
 */
@Injectable()
export class ApiService {
  /**
   * The apiRoute, initializes based on AuthenticationService apiRoute.
   * @type {string}
   */
  public apiRoute: string = null;

  /**
   * Constructor.
   * @param authenticationService
   * @param httpClient
   * @param snackBar
   */
  constructor(private authenticationService: AuthenticationService,
              private httpClient: HttpClient,
              private snackBar: MatSnackBar) {
    this.apiRoute = this.authenticationService.apiRoute;

    this.authenticationService.apiRoute$.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      this.apiRoute = value;
    });
  }

  /**
   * Construct a GET request which interprets the body as JSON and returns the full response.
   * @param url
   * @param query
   * @param pageSize
   * @param page
   * @param orderBy
   * @param sortOrder
   * @param defaultValue
   * @param updateToken
   * @returns an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  get(url: string, query?: string, pageSize?: number, page?: number, orderBy?: string, sortOrder?: string, defaultValue?: any, updateToken: boolean = true): Observable<any> {
    let queryString = '?';
    const lastCharacter = url[url.length - 1];
    if (lastCharacter === '&') {
      queryString = '';
    }
    if (query) {
      queryString += 'query=' + query + '&';
    }
    if (pageSize) {
      queryString += 'pageSize=' + pageSize + '&';
    }
    if (page) {
      queryString += 'page=' + page + '&';
    }
    if (orderBy) {
      queryString += 'orderBy=' + orderBy + '&';
    }
    if (sortOrder) {
      queryString += 'sortOrder=' + sortOrder + '&';
    }
    if (defaultValue) {
      queryString += 'default=' + defaultValue;
    }

    return this.httpClient.get(this.apiRoute + url + queryString, {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.headers.get('Authorization') && updateToken) {
          this.authenticationService.token = response.headers.get('Authorization');
        }

        return response.body;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Performs a POST request.
   * @param url
   * @param data
   * @param updateToken
   */
  post(url: string, data: any, updateToken: boolean = true): Observable<any> {
    return this.httpClient.post(this.apiRoute + url, data, {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.headers.get('Authorization') && updateToken) {
          this.authenticationService.token = response.headers.get('Authorization');
        }

        return response.body;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Performs a PATCH request.
   * @param url
   * @param data
   * @param updateToken
   */
  patch(url: string, data: any, updateToken: boolean = true): Observable<any> {
    return this.httpClient.patch(this.apiRoute + url, data, {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.headers.get('Authorization') && updateToken) {
          this.authenticationService.token = response.headers.get('Authorization');
        }

        return response.body;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Performs a PUT request.
   * @param url
   * @param data
   * @param updateToken
   */
  put(url: string, data: any, updateToken: boolean = true): Observable<any> {
    return this.httpClient.put(this.apiRoute + url, data, {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.headers.get('Authorization') && updateToken) {
          this.authenticationService.token = response.headers.get('Authorization');
        }

        return response.body;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Performs a DELETE request.
   * @param url
   * @param data
   * @param updateToken
   */
  delete(url: string, data: any = null, updateToken: boolean = true): Observable<any> {
    return this.httpClient.request('delete', this.apiRoute + url, {
      body: data,
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.headers.get('Authorization') && updateToken) {
          this.authenticationService.token = response.headers.get('Authorization');
        }

        return response.body;
      }),
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Downloads a file. (GET)
   * @param url
   * @param query
   */
  download(url: string, query: string = null): Observable<any> {
    let queryString = '?';
    const lastCharacter = url[url.length - 1];
    if (lastCharacter === '&') {
      queryString = '';
    }
    if (query) {
      queryString += query;
    }

    return this.httpClient.get(this.apiRoute + url + queryString, {
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token),
      responseType: 'blob',
    }).pipe(
      catchError((error: any) => this.handleError(error))
    );
  }

  /**
   * Upload a file. (POST)
   * @param url
   * @param file
   */
  upload(url: string, file: File): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file);

    const req = new HttpRequest('POST', this.apiRoute + url, data, {
      reportProgress: true,
      headers: new HttpHeaders().set('Authorization', this.authenticationService.token)
    });

    return this.httpClient.request(req);
  }

  /**
   * Handles an error and set the proper message.
   * @param err
   */
  private handleError(err: HttpErrorResponse | any) {
    if (err.error && err.error.message) {
      if (err.error.message === 'Bad Request tokenExpired'
        || err.error.message === 'Token has expired'
        || err.error.message === 'Token is invalid'
        || err.error.message === 'Unauthorized Token has expired and can no longer be refreshed'
        || err.error.message === 'Token has expired and can no longer be refreshed'
        || err.error.message === 'The token has been blacklisted'
        || err.error.message === 'Unauthorized Token Signature could not be verified'
        || err.error.message === 'Unauthorized The token has been blacklisted') {
        this.snackBar.open('Tu sesión ha caducado, inicia sesión de nuevo.', 'Cerrar', {duration: 10000});
        this.authenticationService.logout('../login');
      } else if (err.error.message.includes('No query results for model [App\\Wallet]')) {
        this.snackBar.open('Tu sesión ha caducado, inicia sesión de nuevo.', 'Cerrar', {duration: 10000});
        this.authenticationService.logout('../login');
      } else {
        console.error(err.error.message);
      }

      return throwError(err.error.message);
    } else {
      return throwError(err.error);
    }
  }
}
