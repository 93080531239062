<div fxLayout="row wrap" fxLayoutAlign="start start">
  <div *ngIf="loading" style="width: 100%; height: 100%;">
    <bracelit-preloader [absoluteCentered]="true"></bracelit-preloader>
  </div>

  <div *ngIf="!loading" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="voucher-title bold-20px-center-primary primary-border-100"
    style="position:relative; width:100% !important;display:flex; align-items: center; justify-content: space-between !important; border-bottom:none; margin: 15px 0;">
      <div class="close_container" style="position: absolute; right: 0;" (click)="onClose()">
        <div class="close"></div>
      </div>
    </div>
    <div class="voucher-title bold-20px-center-marine primary-border-100"
    style="width:100% !important;display:flex; align-items: center; justify-content: center !important; border-bottom:none; margin-top: 15px">
      {{voucherBlueprint ? voucherBlueprint.name : voucher.name}}
    </div>
    <div *ngIf="voucher && !data.event.isTicketing"  fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" style="margin-top: 12px">
      <div class="voucher-text-row light-16px-center-marine" style="display: flex; align-items: center; flex-direction:row; margin-top:10px; margin-bottom:10px">
        <ng-container *ngIf="voucher" >
          <div class="secondary-background-full circulo"></div>
          {{leftLabel}} {{voucher.usesLeft|number: '1.2-2'}} {{tokensLabel2}}
        </ng-container>
      </div>
      <div *ngIf="voucher" style="background-color:#C0C4CA; width: 100% !important; height:1px !important; margin: 0px 12px 0px 12px"></div>
      <div class="voucher-text-row light-16px-center-marine" style="display: flex; align-items: center; flex-direction:row; margin-top:10px; margin-bottom:10px">
        <ng-container *ngIf="voucher" >
          <div class="secondary-background-empty circulo"></div>
          {{leftLabel2}} {{gastado|number: '1.2-2'}} {{tokensLabel2}}
        </ng-container>
      </div>
      <div *ngIf="voucher" style="background-color:#C0C4CA; width: 100% !important; height:1px !important; margin: 0px 12px 0px 12px"></div>

      <ng-container *ngIf="voucherBlueprint">
        <div *ngIf="voucherBlueprint.moneyExtra > 0" class="voucher-text-row voucher-text-margin light-16px-center-marine" fxFlex="100" fxLayoutAlign="center center" style="margin-top: 20px">
          {{messageMoneyExtra}}
        </div>
        <div *ngIf="voucherBlueprint.moneyExtra > 0" class="voucher-text-row bold-16px-center-marine" fxFlex="100" fxLayoutAlign="center center">
          {{voucherBlueprint.moneyExtra | number: '1.0-2'}} €
        </div>
  
        <div *ngIf="voucherBlueprint.uses > 0 && voucher.usesLeft <= voucherBlueprint.uses" class="voucher-text-row voucher-text-margin bold-16px-center-marine" fxFlex="100" fxLayoutAlign="center center" style="margin-top: 20px">
          Total {{tokenLabel2}}s: {{voucherBlueprint.uses}}
        </div>
        <div *ngIf="voucherBlueprint.uses > 0 && voucher.usesLeft > voucherBlueprint.uses" class="voucher-text-row voucher-text-margin bold-16px-center-marine" fxFlex="100" fxLayoutAlign="center center" style="margin-top: 20px">
          Total {{tokenLabel2}}s: {{voucher.usesLeft * voucherBlueprint.uses}}
        </div>
      </ng-container>
    </div>
    <!--<div *ngIf="!data.event.isTicketing && products.length > 0" class="voucher-text-row voucher-text-margin light-16px-center-marine" fxFlex="100"
         fxLayoutAlign="center center" style="margin-top: 20px">
      {{messageLabel}}
    </div>
    <div *ngIf="!data.event.isTicketing" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
      <div *ngFor="let product of products"
           class="voucher-text-row bold-16px-right-marine"
           fxFlex="100"
           fxLayoutAlign="center center"
           fxLayoutGap="14px" style="padding-bottom: 10px;">
        <div>{{voucherBlueprint.uses / product.pivot.usesCost}} {{product.name}}</div>
      </div>
    </div>-->
    <div *ngIf="(voucherBlueprint !== undefined) && (voucherBlueprint.description !== null)"
         class="voucher-text-row voucher-text-margin light-16px-center-marine" fxFlex="100"
         fxLayoutAlign="center center">
      {{voucherBlueprint.description}}
    </div>

    <div *ngIf="(voucherBlueprint !== undefined) && (voucherBlueprint.preSaleOfferStart !== null) && (voucherBlueprint.preSaleOffer !== null)"
      style="display: flex; flex-direction: column; margin: 12px 0;">
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100" style="font-weight: bold !important"
      fxLayoutAlign="center center">Intervalo de compra</div>
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100"
         fxLayoutAlign="center center">
        Inicio: {{voucherBlueprint.preSaleOfferStart}}
      </div>
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100"
          fxLayoutAlign="center center">
        Fin: {{voucherBlueprint.preSaleOffer}}
      </div>
    </div>
    <div *ngIf="(voucherBlueprint !== undefined) && (voucherBlueprint.startingDate !== '2000-01-01 00:00:00') && (voucherBlueprint.endingDate !== '3000-01-01 00:00:00')"
      style="display: flex; flex-direction: column; margin: 12px 0;">
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100" style="font-weight: bold !important"
      fxLayoutAlign="center center">Intervalo de uso</div>
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100"
         fxLayoutAlign="center center">
        Inicio: {{voucherBlueprint.startingDate}}
      </div>
      <div class="voucher-text-row light-16px-center-marine" fxFlex="100"
          fxLayoutAlign="center center">
        Fin: {{voucherBlueprint.endingDate}}
      </div>
    </div>
  </div>
</div>
