<div fxLayout="column wrap" fxLayoutAlign="start start">
  <!--<bracelit-preloader *ngIf="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center;"></bracelit-preloader>-->
  <bracelit-preloader *ngIf="loading" [absoluteCentered]="true"></bracelit-preloader>

  <div *ngIf="!loading" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" style="padding: 0px 20px 0px 20px; width: 100%;">
    <div style="display: flex !important; align-items:center !important; justify-content:right !important;
    height:fit-content !important; width:100% !important;"
    (click)="onGoBack()">
      <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
    </div>
    <div style="display: flex !important; align-items:center !important; justify-content:center !important;
    height:fit-content !important; width:100% !important; flex-direction: column;">
      <mat-icon svgIcon="transactions-large" class="primary-color" style="width: 49px; height: 49px;"></mat-icon>
      <div class="voucher-title bold-20px-center-marine primary-border-100" style="margin-bottom: 20px !important; margin-top: 10px !important;">
        {{ 'payments.history' | translate }}
      </div>
    </div>
    <div class="bracelit-card-content"
    style="display: flex !important; flex-direction:column !important; max-height: calc(60vh - 134px) !important; width: 95% !important; overflow-y:scroll !important">

      <div *ngIf="creditMoves.length === 0" class="transaction-row" fxLayout="row wrap" fxFlex="100">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center"
            style="color: #0F223E; padding: 5em 25px; font-size: 18px; text-align: center;">
          <span>{{ 'payments.haveNo' | translate }}</span>
        </div>
      </div>

      <div *ngFor="let credit_move of creditMoves"
          class="transaction-row"
          fxFlex="100">
        <!-- <div class="transaction-icon" fxFlex="36px" fxLayoutAlign="center center">
          <img *ngIf="!credit_move.voucherId"
              [src]="'assets/icons/arrow_pay_' + (credit_move.amount > 0 ? 'up' : 'down') + '.svg'" alt="">

          <img *ngIf="credit_move.voucherId" [src]="'assets/icons/empty_ticket_secondary.svg'" alt="">
        </div> -->
        <div class="transaction-container grey-border-100" fxFlex="calc(100% - 36px)"
            fxLayout="row wrap" fxLayoutAlign="center center" style="margin: 10px 0px 0px 0px;">
            <details style="width: 100%;">
              <summary class="transaction-container transparent-border-100"
              fxLayout="row wrap" fxLayoutAlign="center center" style="margin: 10px 0px 0px 0px; width: 100%;">
                <div class="sub-row" style="display:flex; align-items:center; justify-content:space-between; width:100%">
                  <div class="transaction-title bold-16px-left-marine" fxFlex="60">
                    {{!credit_move.voucherId ?
                    (credit_move.amount < 0 ? checkAction(credit_move) : rechargeText + coinName) : (credit_move.uses < 0 ? payText : rechargeText) + data.voucherLabelMinus}}
                  </div>
                  <div class="transaction-amount bold-16px-right-marine" style="display:flex; align-items:center" >
                    <!-- {{!credit_move.voucherId ? (credit_move.amount|number: '1.2-2')
                    : (credit_move.uses|number: '1.2-2')}} -->
                    {{!credit_move.voucherId ? (credit_move.amount | number)
                    : (credit_move.uses)}}
                    <span *ngIf="!credit_move.voucherId">€</span>
                    <span *ngIf="credit_move.voucherId" style="display:flex; align-items:center">
                      <mat-icon svgIcon="coins" style="width:20px !important" class="marine-color"></mat-icon>
                    </span>
                  </div>
                </div>
                <div class="sub-row" fxFlex="100" style="width: 100%;margin: 0px 0px 10px 0px;">
                  <div class="light-12px-left-grey" fxFlex="50">
                    {{credit_move.created | date : 'dd-MM-yyyy'}} | {{credit_move.created | date : 'HH:mm'}}h
                  </div>
                </div>
              </summary>              
              <div *ngIf="credit_move.paymentMethod !== null && credit_move.paymentMethod !== undefined" class="payment-details-date light-14px-center-primary" 
              style="display: flex; justify-content: space-between; width: 100% !important; margin-bottom: 10px;">
                <div>
                  {{ 'payments.method' | translate}}
                </div>
                <div>
                  {{credit_move.paymentMethod}}
                </div>
              </div>

              <div *ngIf="credit_move.recycling !== null && credit_move.recycling !== undefined" class="payment-details-date light-14px-center-primary" 
              style="display: flex; justify-content: space-between; width: 100% !important; margin-bottom: 10px;">
                <div>
                  {{ 'payments.recycle' | translate}}
                </div>
              </div>

              <div *ngIf="credit_move.saleOrderLines && credit_move.saleOrderLines.length > 0" style="width: 100%;">
                <div *ngFor="let producto of credit_move.saleOrderLines" class="payment-details-date light-14px-center-primary" 
                style="display: flex; justify-content: space-between; width: 100% !important; margin-bottom: 10px;">
                  <div *ngIf="producto.name !== null && producto.name !== undefined && producto.unitPrice !== null && producto.unitPrice !== undefined && producto.quantity !== null && producto.quantity !== undefined">
                    {{producto.name}} {{producto.unitPrice | number}}€ (x{{producto.quantity | number}})
                  </div>
                  <div *ngIf="producto.total !== null && producto.total !== undefined">
                    {{producto.total | number}}€
                  </div>
                </div>
              </div>
              
              <div *ngIf="credit_move.vbName !== null && credit_move.vbName !== undefined" class="payment-details-date light-14px-center-primary" 
              style="display: flex; justify-content: space-between; width: 100% !important; margin-bottom: 10px;">
                <div>
                  {{credit_move.vbName}}
                </div>
                <div>
                  
                </div>
              </div>
            </details>
        </div>
      </div>
    </div>
  </div>
</div>
