<div fxLayout="column wrap" fxLayoutAlign="start start">
    <bracelit-preloader *ngIf="loading" [absoluteCentered]="true" style="padding-top: 60px;"></bracelit-preloader>
    <div *ngIf="!loading" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" style="padding: 0px 20px 0px 20px; width: 100%;">
      <div style="display: flex !important; align-items:center !important; justify-content:right !important;
        height:fit-content !important; width:100% !important;"
        (click)="onClose()">
        <img [src]="'assets/icons/crossClose.svg'" style="cursor: pointer;">
      </div>
      <div *ngIf="!loading" style="display: flex !important; align-items:center !important; justify-content:center !important;
        height:fit-content !important; width:100% !important; margin: 12px 12px 12px 9px;">
        <mat-icon svgIcon="notifications2023Dialog" class="primary-color" style="transform: scale(2);"></mat-icon>
      </div>
      <div style="display: flex !important; align-items:center !important; justify-content:center !important;
        height:fit-content !important; width:100% !important; flex-direction: column;">
        <div class="voucher-title bold-20px-center-marine primary-border-100" style="margin-bottom: 20px !important; margin-top: 10px !important;">
          {{titleText}}
        </div>
      </div>
      
      <div *ngIf="!loading"  fxLayout="row wrap" class="bracelit-card-content"
      style="display: flex !important; flex-direction:column !important; max-height: calc(60vh - 134px) !important; width: 95% !important; overflow-y:scroll !important">
        <div *ngIf="notifications.length === 0" class="transaction-row" fxLayout="row wrap" fxFlex="100" style="padding:0px 0px 15px 0px !important;">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center"
          style="color: #0F223E; padding: 5em 25px; font-size: 18px; text-align: center;">
            <span>{{messageText}}</span>
          </div>
        </div>
        <div *ngFor="let notification of notifications"
             class="transaction-row"
             fxFlex="100">
          <div class="transaction-container grey-border-100" fxFlex="calc(100% - 36px)"
               fxLayout="row wrap" fxLayoutAlign="center center" (click)="expandNotification(notification.id)" style="cursor: pointer;">
            <div class="sub-row" fxFlex="100">
              <div class="transaction-title bold-16px-left-marine" fxFlex="100">
                {{notification.title}}
              </div>
            </div>
            <div class="sub-row" fxFlex="100">
              <div class="light-14px-left-marine" fxFlex="100" style="padding-top: 5px;">
                <span *ngIf="notification.eventName && events.length > 1">{{notification.eventName}}</span>
              </div>
            </div>
            <div class="sub-row" fxFlex="100" style="margin-top: 10px">
              <div class="transaction-units light-14px-left-grey" fxFlex="100"
                  style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;" id={{notification.id}}>
                {{notification.content}}
              </div>
            </div>
            <div class="sub-row" fxFlex="100" style="margin-top: 10px">
              <div class="light-12px-left-grey" fxFlex="50">
                {{notification.createdAt | date : 'dd-MM-yyyy'}} | {{notification.createdAt | date : 'HH:mm'}}h
              </div>
              <div fxFlex="50">
                <!-- <div *ngIf="notification.isEmail" class="transaction-amount light-14px-left-marine">
                  {{typeText}} Email
                </div>
                <div *ngIf="notification.isPush" class="transaction-amount light-14px-left-marine">
                  {{typeText}} Push
                </div> -->
              </div>
            </div>
            <div class="bord" fxFlex="100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>