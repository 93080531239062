<bracelit-preloader *ngIf="loading" [absoluteCentered]="true">
</bracelit-preloader>
<div *ngIf="!loading" class="event-details-container main-container"
     fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">

  <div style="display: flex; justify-content: end; margin-bottom: 5px; margin-top: -10px; width: 100%; max-width: 300px !important" *ngIf="event.hasQR">
    <button class="light-16px-center-marine"
            mat-raised-button
            type="button"
            (click)="onSeeQrCode()" style="box-shadow: none !important; background-color: transparent !important; padding: 0 !important;">
            <mat-icon svgIcon="show-qr" class="primary-color"></mat-icon>
            {{ 'eventDetails.showQR' | translate }}
    </button>
  </div>

  <div fxFlex="100" fxLayoutAlign="center center">
    <mat-card class="bracelit-card white-opacity-background" style="margin-bottom: 25px !important;">
      <mat-card-header class="card-header">
        {{wristbandsService.selectedEvent ? wristbandsService.selectedEvent.name : ' '}}
        <div *ngIf="this.event.hasInformation && this.event.info !== null" (click)="openInfo()" class="history-img-container" style="cursor: pointer; padding-right:5px;" matRipple>
          <mat-icon svgIcon="info-medium"></mat-icon>
        </div>
      </mat-card-header>
      <div class="bracelit-card-content">
        <div class="bracelit-card-row">
          <div class="bracelit-card-row">
            <div style="display: flex; justify-content: space-between ;margin: 0px 8px; width: calc(100% - 16px);">
              <div class="bold-14px-left-primary" style=" margin-top: 10px">
                <span fxLayout="row"
                [ngStyle]="{'visibility': (((this.wristbandsService.selectedWristband['label'] !== 'associated') && (this.wristbandsService.selectedWristband['label'] !== 'changed')) ? 'hidden' : 'visible')}"
                style="
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                color: #0f223e !important;">
                  {{ 'eventDetails.code' | translate }}:
                </span>
                <span class="code-pulsera-number" fxLayout="row" style="margin-top: -3px"
                [ngStyle]="{'visibility': (((this.wristbandsService.selectedWristband['label'] !== 'associated') && (this.wristbandsService.selectedWristband['label'] !== 'changed')) ? 'hidden' : 'visible')}">
                  {{codeWrist}}
                </span>
              </div>
              <div style="margin-top: 30px;
              font-size: 16px;
              font-style: normal;
              font-weight: normal;
              color: #0f223e !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 50%">
                {{this.wristbandsService.selectedWristband.showName}}
              </div>
            </div>
          </div>
        </div>

        <div class="bracelit-card-row bracelit-card-row-second" style="display: flex; align-items: center; padding-top: 20px !important; justify-content: space-between">
          <div style="display: flex; justify-content: left; flex-flow: row; ">
            <div class="history-img-container"  matRipple>
              <mat-icon id="historic" svgIcon="history" matTooltip="{{ 'history' | translate }}" (click)="onOpenHistory()" style=" cursor: pointer; color: #0f223e !important;
              fill: #0f223e !important; margin: 0px 0px 0px -17px !important"></mat-icon>
            </div>
            <div class="history-img-container" style="margin: 0px !important" matRipple>
              <mat-icon *ngIf="event.hasEditableData" (click)="editEventUserForm()" class="primary-color" svgIcon="pencil-small" matTooltip="{{ 'editData' | translate }}"
              style=" cursor: pointer; color: #0f223e !important; fill: #0f223e !important; margin: 0px 0px 0px -17px !important"></mat-icon>
            </div>
            <!-- <div *ngIf="leadScanner && !productScanner" fxLayout="row" fxLayoutAlign="end end">
              <div (click)="onShowContacts()" class="history-img-container" matRipple>
                <mat-icon svgIcon="account" class="primary-color"></mat-icon>
              </div>
            </div> -->
          </div>
          <div style="display: flex; align-items: center;">
            <div class="text-adjust-bottom regular-16px-left-primary-200" style="display: flex; align-items: center; color: #0f223e !important;">{{coinName.charAt(0).toUpperCase()}}{{coinName.slice(1)}}</div>
            <div class="number-container light-20px-right-primary"
            style="display: flex; align-items: center; margin-left: 18px; color: #0f223e !important; fill: #0f223e !important;">
              {{wristbandsService.selectedWallet ? (wristbandsService.selectedWallet.money | number) : '-'}}
            </div>
            <div class="img-container" style="display: flex; align-items: center;">
              <mat-icon svgIcon="coins" style="color: #0f223e !important; fill: #0f223e !important;"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <mat-slide-toggle *ngIf="this.event.wearablesManagement" (change)="activateOrDeactivate($event)" [checked]="checked" style="padding-left: 20px">{{this.checked ? 'Desactivar' : 'Activar'}}
      </mat-slide-toggle>
    </mat-card>
  </div>

  <ng-container *ngIf="vouchers && vouchers.length > 0" style="padding-bottom: 30px">
    <div class="contenedor" style="padding-top: 0px !important; max-height: 25%; overflow-y: scroll;">
      <div class="bracelit-card" fxFlex="100" fxLayoutAlign="center center" style="margin-bottom: 35px !important; box-shadow: none !important">
        <div style="padding: 0px !important; width: 100% !important; max-height: 100% !important;">
          <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
            <ng-container *ngFor="let voucher of vouchers; let i = index">
              <div *ngIf="voucher.moneyExtra === null || voucher.moneyExtra === 0" class="light-16px-center-primary vouchers-form-row linea-debajo"
                  style="padding-bottom: 10px; color: #0f223e !important; fill: #0f223e !important;"
                  fxFlex="100"
                  fxLayoutAlign="center center"
                  fxLayout="column wrap">

                <div class="voucher-data primary-border-100" fxFlex="calc(100%)" fxLayoutAlign="center center"
                style="display: flex !important; place-content: flex-start !important; width: 100% !important">
                  <div class="bold-14px-left-primary block-with-text-container" fxFlex="100" fxLayoutAlign="start center">
                    <p style="color: #0f223e !important; fill: #0f223e !important; margin: 10px 0 5px 0 !important">{{voucher.name}}</p>
                  </div>
                  <div class="vouId" *ngIf="voucher.hasCancelation" (click)="cancelVoucher(voucher.id, i)"
                        style="background-color: #9D0D14; color: white; cursor: pointer; font-size: 16px; font-weight: bold; margin-bottom: 8px;
                        padding: 5px 10px;  text-align: center; justify-content: center; align-items: center; display: flex; margin-right: 8px;">
                        x
                  </div>
                </div>

                <div *ngIf="!event.isTicketing" style="display:flex !important; justify-content:space-between !important; width:100%">
                  <div class="voucher-uses-row" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px grid">
                    <div class="secondary-background-full"
                    style="border-radius: 50%; width: 3px !important; height: 3px !important; padding: 0 !important; margin: 0px 12px 12px 0px"
                    *ngFor="let item of buildArray(voucher.usesLeft)"></div>
                    <div *ngIf="(voucher.usesLeft % 1) !== 0" class="secondary-background-semi"
                    style="border-radius: 50%; width: 3px !important; height: 3px !important; padding: 0 !important; margin: 0px 12px 12px 0px !important;"></div>
                    <ng-container *ngIf="voucher.uses > voucher.usesLeft">
                      <div class="secondary-background-empty"
                      style="border-radius: 50%; width: 3px !important; height: 3px !important; padding: 0 !important; margin: 0px 12px 12px 0px"
                      *ngFor="let item of buildArray(voucher.uses - voucher.usesLeft)"></div>
                    </ng-container>
                  </div>
                  <div class="bold-14px-left-primary" style="display: flex; align-items: center; margin-left: 10px; font-weight: bold !important; font-size: 14px !important; color: #0f223e !important; fill: #0f223e !important;">
                    <ng-container *ngIf="voucher.usesLeft <= voucher.uses">
                      {{voucher.usesLeft}}/{{voucher.uses}}
                    </ng-container>
                    <ng-container *ngIf="voucher.usesLeft > voucher.uses">
                      {{voucher.usesLeft}}/{{voucher.uses*voucher.usesLeft}}
                    </ng-container>
                    <mat-icon (click)="openCouponDetails(voucher)" svgIcon="info" class="primary-color" style="cursor: pointer; width: 17px; height: 17px; margin-left: 6px"></mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="rooms && rooms.length > 0" style="padding-bottom: 30px">
    <!--Si tenemos bonos-->
    <div class="vouchers-section-title bold-16px-left-primary">
      <div class="bracelit-card-width" style="color: #0f223e !important; fill: #0f223e !important;">{{ 'eventDetails.activities' | translate }}</div>
    </div>

    <div class="vouchers-container" fxLayoutGap="12px">
      <!--ngFor con los bonos-->
      <div *ngFor="let room of rooms" class="voucher-container">
        <div class="bracelit-card-width">
          <div class="activity-info-row">
            <div fxLayout="row" fxLayoutAlign="center center">
              <img [src]="'assets/icons/bus.svg'" alt="" fxLayout="column" >
              <div class="regular-16px-left-primary activity-info-title" style="color: #0f223e !important; fill: #0f223e !important;" fxLayout="row">
                {{room.name.split('2022')[0]}}
            </div>
          </div>
            <div fxLayout="row">
            <img [src]="'assets/icons/calendar.svg'" alt="" fxLayout="column">
            <div class="regular-16px-left-primary activity-info-container" fxLayout="column" style="color: #0f223e !important; fill: #0f223e !important;">
              {{room.name.split('2022-')[1].split(' ')[0].split('-')[1]+'-'+room.name.split('2022-')[1].split(' ')[0].split('-')[0]+'-2022'}}
            </div>
            <img [src]="'assets/icons/timer.svg'" alt="" fxLayout="column">
            <div class="regular-16px-left-primary activity-info-container" fxLayout="column" style="color: #0f223e !important; fill: #0f223e !important;">
              {{room.name.split('2022')[1].split(' ')[1].split('--')[0]}}
            </div>
          </div>
          <div fxLayout="row">
            <img [src]="'assets/icons/place.svg'" alt="" fxLayout="column">
            <div class="regular-16px-left-primary activity-info-container" fxLayout="column" style="color: #0f223e !important; fill: #0f223e !important;">
              {{room.name.split('--')[1]}}
            </div>
          </div>
          </div>
          <div class="voucher-uses-row" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="14px grid">
            <!--ngFor icono por cada uso disponible-->
            <!-- <img [src]="'assets/bracelit/voucher_use_full-new.svg'"
                 alt=""> -->
            <!-- <img *ngIf="(voucher.usesLeft % 1) !== 0" [src]="'assets/bracelit/voucher_use_half-new.svg'" alt=""> -->
            <!--ngFor icono por cada uso gastado (totales - disponibles)-->
            <!-- <ng-container *ngIf="voucher.uses > voucher.usesLeft">
              <img [src]="'assets/bracelit/voucher_use_empty-new.svg'"
                   alt=""
                   *ngFor="let item of buildArray(voucher.uses - voucher.usesLeft)">
            </ng-container> -->
            <!--            <img [src]="'assets/bracelit/voucher_use_empty.svg'" alt="">-->
            <!--            <img [src]="'assets/bracelit/voucher_use_empty.svg'" alt="">-->
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!--<div *ngIf="vouchers && vouchers.length === 0 && eventHasVouchers" class="empty-state-container">
    <div class="empty-state-container-main-text bold-20px-center-primary" style="color: #0f223e !important; fill: #0f223e !important;">
      {{ !event.isTicketing ? ('eventDetails.haveNo' | translate) : ('eventDetails.haveNoTicket' | translate) }}
    </div>
  </div>-->

  <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 15px; padding-bottom: 120px;">
    <div *ngIf="leadScanner && bidirectional && !productScanner" class="product-scanner-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <button id="product-scanner-button"
              class="primary-background bold-20px-center-white"
              mat-raised-button
              type="button"
              (click)="onLeadScanner()" style="box-shadow: none !important; width: 320px !important">
              Lead scanner
      </button>
    </div>
    <div *ngIf="leadScanner && productScanner" class="product-scanner-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <button id="product-scanner-button"
              class="primary-background bold-20px-center-white"
              style="color: white !important;"
              mat-raised-button
              type="button"
              (click)="onProductScanner()"  style="width: 320px !important; box-shadow: none !important">
              Quiz
      </button>
    </div>

    <div *ngIf="!event.isTicketing && event.isPaymentGatewayEnabled && eventId !== '04260fda-0945-42c8-9936-cbd73c2bfa80'" class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <button id="recharge-button"
              class="primary-background bold-20px-center-white"
              mat-raised-button
              type="button"
              (click)="onRechargeWristband()" style="width: 320px !important; box-shadow: none !important;">
              {{ 'eventDetails.recharge' | translate }} {{wristbandLabel}}
      </button>
    </div>

    <!-- FESTIVAL DE LAS IDEAS -->
    <div *ngIf="eventId === '04260fda-0945-42c8-9936-cbd73c2bfa80'" class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <button id="recharge-button"
              class="primary-background bold-20px-center-white"
              mat-raised-button
              type="button"
              (click)="goToPopUp()" style="width: 320px !important; box-shadow: none !important;">
              Pagar fianza
      </button>
      <div style="width: 320px !important;">
        * Para utilizar los auriculares en Plaza de España y seguir las conversaciones del Festival de las Ideas, es necesario abonar la fianza previamente.
      </div>
    </div>

    <!-- CHAPUZA LA TAJA -->
    <ng-container *ngIf="(eventId === '7545ed70-72bc-4095-8e59-dd576d20924e') && (checkPrincipalMember() === true)">
      <div class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <button id="recharge-button"
                class="primary-background bold-20px-center-white"
                mat-raised-button
                type="button"
                style="width: 320px !important; box-shadow: none !important; border: 1px solid #0F223E; color: #0F223E !important; background-color: white !important"
                (click)="showIframe()">
                Reservar mesa
        </button>
      </div>
      <div class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <button id="recharge-button"
                class="primary-background bold-20px-center-white"
                mat-raised-button
                type="button"
                style="width: 320px !important; box-shadow: none !important; border: 1px solid #0F223E; color: #0F223E !important; background-color: white !important"
                (click)="abrirCarta()">
                Ver carta
        </button>
      </div>
      <div class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <button id="recharge-button"
                class="primary-background bold-20px-center-white"
                mat-raised-button
                type="button"
                style="width: 320px !important; box-shadow: none !important; border: 1px solid #0F223E; color: #0F223E !important; background-color: white !important"
                (click)="redirectEditProfile()">
                Editar perfil
        </button>
      </div>
    </ng-container>

    <!-- CHAPUZA FIN FUN (MBA KIDS) -->
    <div *ngIf="event.id === '938c8da9-4e2e-48f6-861e-cbdd23e6695f' || event.id === '1aa82077-4f80-42e0-b46c-feaab8c12d35' || event.id === '3c8d24d8-011d-4e11-a13d-c28c0ac89a28'" class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <button id="recharge-button"
              class="primary-background bold-20px-center-white"
              mat-raised-button
              type="button"
              (click)="changeFlagMBA()" style="width: 320px !important; box-shadow: none !important;">
              {{ 'eventDetails.admin' | translate }} {{wristbandLabel}}
      </button>
      <div *ngIf="flagMBA" style="width: 320px; display: flex; flex-direction: column; gap:15px; padding-top: 25px;">
        <div class="input-recarga" floatLabel="never" style="width: 320px !important">
          <input #bracelsInput="matInput"
                (change)="changeMoneyMBA(1, false)"
                (keyup)="changeMoneyMBA(1, false)"
                [formControl]="myForm.controls['money']"
                [id]="'bracels'"
                [type]="'number'"
                min="0"
                step="any"
                class="light-16px-left-marine"
                [placeholder]="'Introduce una cantidad €'"
                style="margin-right: 4px !important; color: #0f223e !important;"
                matInput>

            <div (click)="changeMoneyMBA(0, false)" class="history-img-container input-recarga" matRipple
            fxLayout="column" fxLayoutAlign="center center"
            style="cursor: pointer; width: 36px; height: 36px; min-width: 36px; padding: 5px; border-width: 0px 0px 0px 1px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
            <mat-icon svgIcon="right_reset_icon" class="primary-color"></mat-icon>
          </div>
        </div>
        <div style="width: 320px; display: flex; flex-direction: row; gap: 10px;">
          <button id="recharge-button"
                class="primary-background bold-20px-center-white"
                mat-raised-button
                type="button"
                (click)="changeMoneyMBA(1, true)" style="width: 160px !important; box-shadow: none !important;">
                {{ 'eventDetails.add' | translate }}
          </button>
          <button mat-raised-button type="button" class="white-background regular-20px-center-marine"
          style="box-shadow: none !important; width: 160px !important; border: 1px solid #0F223E;"
                (click)="changeMoneyMBA(-1, true)">
                {{ 'eventDetails.subtract' | translate }}
          </button>
        </div>

      </div>
    </div>

    <div *ngIf="onlineOrders"
         class="buy-orders-container" fxFlex="100" fxLayoutAlign="center center">
      <button mat-raised-button type="button" class="white-background bold-20px-center-marine" id="control"
              (click)="onBuyOnlineOrders()" style="box-shadow: none !important; display: flex; align-items: center; justify-content: center; width: 320px !important; border: 1px solid #0F223E;">
              {{ 'eventDetails.order' | translate }}
      </button>
    </div>

    <div *ngIf="eventHasVouchers && allVouchersBP.length > 0" class="associate-button-container" style="margin-top: 0 !important;" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayout="row">
       <button mat-raised-button type="button" class="white-background bold-20px-center-marine" id="control2" #control
        (click)="onBuyVouchers()" style="box-shadow: none !important; width: 320px !important; border: 1px solid #0F223E;">
        {{ !event.isTicketing ? ('eventDetails.buy' | translate) : ('eventDetails.book' | translate) }} {{voucherLabelMayus}}
       </button>
      </div>
    </div>

    <!-- CHAPUZA EL LAGARILLO -->
    <ng-container *ngIf="eventId === '68c75f7b-3a2d-4334-9d13-2bd08c4d5e8d' && dataWrist">
      <div *ngIf="dataWrist.name" style="margin-top: 40px">Nombre: {{dataWrist.name}}</div>
      <div *ngIf="dataWrist.surname" style="margin-top: 10px">Apellidos: {{dataWrist.surname}}</div>
      <div *ngIf="dataWrist.genero" style="margin-top: 10px">Género: {{dataWrist.genero}}</div>
      <div *ngIf="dataWrist.genero === 'Mujer' && dataWrist.taquilla" style="margin-top: 10px">Taquilla: {{dataWrist.taquilla}}</div>
    </ng-container>

    <!-- CHAPUZA EVENTIK -->
    <ng-container *ngIf="eventId === '47c562a9-5e1f-4840-a064-80115062f386'">
      <div class="recharge-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <button id="recharge-button"
                class="primary-background bold-20px-center-white"
                mat-raised-button
                type="button"
                (click)="goToQuestionary()" style="width: 320px !important; box-shadow: none !important;">
                Quiniela
        </button>
      </div>
      <div class="associate-button-container" style="margin-top: 0 !important;" fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
        <button mat-raised-button type="button" class="white-background bold-20px-center-marine" id="control2" #control
          (click)="goToQuiz()" style="box-shadow: none !important; width: 320px !important; border: 1px solid #0F223E;">
          Concurso fotografía
        </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="event.isMoneyReturned && (this.wristbandsService.selectedWristband['label'] === 'associated' || this.wristbandsService.selectedWristband['label'] === 'changed' || !obligatoryAssociation || event.isTicketing || event.hasQR)" style="position: absolute; bottom: 70px; width: 100%; background: #FFFFFF;"
       fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
       <div *ngIf="errorReturnNumber === 1" fxLayout="row" class="noAssociated">
        {{ 'eventDetails.errorReturn1' | translate }}
      </div>
      <div *ngIf="errorReturnNumber === 2" fxLayout="row" class="noAssociated">
        {{ 'eventDetails.errorReturn2' | translate }}
      </div>
       <div fxLayout="row">
        <div (click)="goToMoneyReturned()"
              style="width: auto !important; color: #0F223E; font-size: 18px; text-align: center; cursor: pointer;
              font-style: normal; font-weight: 700; line-height: normal; border-bottom: 3px solid #0F223E;">
          {{ 'eventDetails.return' | translate }}
       </div>
       </div>
    </div>
    <div *ngIf="this.intervalReturnMoneyStarting && this.intervalReturnMoneyEnding && (this.wristbandsService.selectedWristband['label'] === 'associated' || this.wristbandsService.selectedWristband['label'] === 'changed' || !obligatoryAssociation || event.isTicketing || event.hasQR)"
     class="associate-button-container" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="position: absolute; bottom: 0;  height: 50px; width: 100%; background: #FFFFFF;">
      <div fxLayout="row">
       <div (click)="openInfoDev()"
              style="width: auto !important; color: #0F223E; font-size: 18px; text-align: center; cursor: pointer;
              font-style: normal; font-weight: 700; line-height: normal; border-bottom: 3px solid #0F223E;">
        {{ 'eventDetails.infoDev' | translate }}
      </div>
      </div>
    </div>

    <div *ngIf="this.wristbandsService.selectedWristband['label'] !== 'associated' && obligatoryAssociation && !event.isTicketing"
          style="display: flex; flex-direction: column; justify-content: center; align-items: center;
          border-radius: 30px 30px 0px 0px; background: #D9D9D9; position: absolute; bottom: 0; width: 100%;">
        <div class="noAssociated" fxFlex="100" fxLayoutAlign="center center">
          {{ 'eventDetails.messageAssociate' | translate }}
        </div>
        <div>
          <button type="button" class="bold-16px-center-marine" (click)="onNavigateToNewWristband()"
            style="border: 1px solid #0F223E; border-radius: 50px; padding: 7px 15px !important; background-color: transparent;
            margin-bottom: 20px; box-shadow: none; display: flex; justify-content: center; align-items: center; cursor: pointer;">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" style="padding-right: 7px;">
              <path d="M13.6357 7.71819L13.6457 7.71919C14.6032 7.91538 15.4817 8.38906 16.1717 9.08119C16.8633 9.7726 17.3371 10.6515 17.5347 11.6092C17.6696 12.2745 17.6693 12.9601 17.5337 13.6252C17.3376 14.5831 16.8635 15.4621 16.1707 16.1522L17.5847 17.5662C18.5526 16.5977 19.2159 15.3671 19.4927 14.0262C19.6845 13.0963 19.6845 12.1371 19.4927 11.2072C19.2192 9.86556 18.5558 8.63449 17.5857 7.66819C16.9488 7.02901 16.1939 6.51958 15.3627 6.16819C14.9391 5.98882 14.4986 5.85214 14.0477 5.76019C13.9107 5.73219 13.7727 5.71719 13.6357 5.69719V2.61719L9.63575 6.61719L13.6357 10.6172V7.71819ZM6.18575 15.3412C6.35975 15.7532 6.57775 16.1532 6.83175 16.5312C7.08075 16.9012 7.36875 17.2492 7.68575 17.5652C8.32394 18.2035 9.079 18.7131 9.90975 19.0662C10.3347 19.2462 10.7777 19.3832 11.2247 19.4742C11.3917 19.5082 11.5627 19.5302 11.7327 19.5522V22.4962L15.7327 18.4962L11.7327 14.4962V17.5262C11.6977 17.5202 11.6607 17.5232 11.6257 17.5152C10.6683 17.319 9.78979 16.8453 9.09975 16.1532C8.63482 15.6892 8.26597 15.1381 8.01434 14.5314C7.76271 13.9247 7.63323 13.2743 7.63333 12.6175C7.63342 11.9607 7.76308 11.3103 8.01488 10.7037C8.26669 10.097 8.63568 9.54601 9.10075 9.08219L7.68675 7.66719C7.04824 8.30505 6.53892 9.06021 6.18675 9.89119C5.82193 10.7536 5.63453 11.6807 5.63575 12.6172C5.63511 13.5528 5.82213 14.4791 6.18575 15.3412Z" fill="#0F223E"/>
            </svg>
              {{ 'eventDetails.buttonAssociate' | translate }}
          </button>
        </div>
    </div>
  </div>

  <!-- CHAPUZA EVENTIK -->
  <ng-container *ngIf="eventId === '47c562a9-5e1f-4840-a064-80115062f386'">
    <div style="position: absolute; bottom: 0">
      <img [src]="'app/shared/assets/images/BANNER-CONGRESO-GIJON-2024.jpg'" alt="" style="width: 100% !important; height: auto !important; max-width: 768px;">
    </div>
  </ng-container>
</div>
